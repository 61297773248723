.wizardForm {
  padding: 25px;
  border-radius: 8px;
  border: 1px solid #c8d6e5;
}

input[type=checkbox], input[type=radio]
{
  /* Double-sized Checkboxes */
  -ms-transform: scale(1.5); /* IE */
  -moz-transform: scale(1.5); /* FF */
  -webkit-transform: scale(1.5); /* Safari and Chrome */
  -o-transform: scale(1.5); /* Opera */
}

.form-check-label {
  margin-left: 1rem;
}
